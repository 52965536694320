@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,300");

:root {
  --font-family: "Open Sans";

  --color-bg: #1e1e1e;
  --color-footer: #333333;

  --color-text-heading: #f3f3f3;
  --color-text: #977b6c;
  --color-footer-text: #838383;
}
