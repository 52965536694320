* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background: var(--color-bg);
}

.danger {
  color: red;
  font-family: var(--font-family);
  font-size: 0.7rem;
  text-align: center;
}

.mt-1 {
  margin-top: 1rem;
}
